import axios, { AxiosRequestConfig } from 'axios';

const API_URL = window.API_URL;
const API_VERSION = window.API_VERSION;
const WORKER_VERSION = window.WORKER_VERSION;
const WORKER_URL = window.WORKER_URL;

const axiosConfigApi: AxiosRequestConfig = {
  baseURL: API_URL + '/api/' + API_VERSION,
  // timeout: 3000,
};

const axiosConfigWorker: AxiosRequestConfig = {
  baseURL: WORKER_URL + '/api/' + WORKER_VERSION,
  // timeout: 3000,
};

export const worker = axios.create(axiosConfigWorker);
export const api = axios.create(axiosConfigApi);

worker.interceptors.response.use(
  response => response.data,
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => response.data,
  error => {
    return Promise.reject(error);
  }
);
